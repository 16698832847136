<template>
  <div>
    <b-row>
      <b-modal ref="my-modal" :hide-footer="true" :hide-header="true">
        <div class="card card-custom">
          <div class="card-header">
            <h3 class="card-title">
              {{ newUser ? "Create User" : "Update User" }}
            </h3>
          </div>

          <form v-on:submit.prevent>
            <div class="card-body">
              <div class="form-group mb-8"></div>
              <div class="form-group">
                <label>User Name<span class="text-danger">*</span></label>
                <input class="form-control" v-model="selectedUser.userName" />
              </div>
            </div>
            <div class="card-footer" style="display: flex; justify-content: flex-end;">
              <button type="submit" class="btn btn-primary mr-2" :disabled="disableSubmit()" @click="submitUser">
                Submit
              </button>
            </div>
          </form>
        </div>
      </b-modal>

      <b-col lg="12">
        <div class="card card-custom gutter-b card-stretch card-shadowless bg-light">
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                System Users
              </span>

              <span class="text-muted mt-3 font-weight-bold font-size-sm">
                These are all system users
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->
            <div class="table-responsive">
              <table class="table table-borderless table-vertical-center">
                <thead>
                  <tr>
                    <th class="p-0" style="width: 50px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                    <th class="p-0" style="min-width: 100px"></th>
                  </tr>
                </thead>
                <tbody>
                  <!--ADD BUTTON-->
                  <tr>
                    <td class="pl-0"></td>
                    <td class="pl-0"></td>
                    <td class="text-right pr-0">
                      <a href="#" @click="addUser" class="btn btn-icon btn-light btn-sm">
                        <span class="svg-icon svg-icon-md svg-icon-primary">
                          <!--begin::Svg Icon-->
                          <inline-svg src="media/svg/icons/Communication/Add-user.svg"></inline-svg>
                          <!--end::Svg Icon-->
                        </span>
                      </a>
                    </td>
                  </tr>

                  <template v-for="(item, i) in users">
                    <tr v-bind:key="i">
                      <td class="pl-0 py-4">
                        <div class="symbol symbol-50 symbol-light mr-1">
                          <span class="symbol-label">
                            <img :src="picture" class="h-50 align-self-center" alt="" />
                          </span>
                        </div>
                      </td>
                      <td class="pl-0">
                        <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">
                          {{ item.userName }}
                        </a>
                        <div>
                          <a class="text-muted font-weight-bold">
                            {{ item.id }}
                          </a>
                        </div>
                      </td>
                      <td class="text-right pr-0">
                        <a class="btn btn-icon btn-light btn-sm mx-3" @click="editUser(item)">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <!--begin::Svg Icon-->
                            <inline-svg src="media/svg/icons/Communication/Write.svg"></inline-svg>
                            <!--end::Svg Icon-->
                          </span>
                        </a>
                        <span class="d-inline-block" tabindex="0" v-b-tooltip.hover.top>
                          <b-link class="btn btn-icon btn-light btn-sm" @click="deleteUser(item)">
                            <span class="svg-icon svg-icon-md svg-icon-primary">
                              <!--begin::Svg Icon-->
                              <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                              <!--end::Svg Icon-->
                            </span>
                          </b-link>
                        </span>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!--end::Table-->
          </div>
          <!--end::Body-->
        </div>
        <!-- <div class="row">
      <div class="col-md-12">
        <b-table striped hover :items="contacts"></b-table>
      </div>
    </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      users: [],
      selectedUser: {},
      newUser: false,
    };
  },

  components: {},

  computed: {
    picture() {
      return process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg";
    },
  },
  methods: {
    deleteUser(user) {
      this.$bvModal
        .msgBoxConfirm(`Are you sure you want to delete ${user.userName}?`)
        .then((value) => {
          if (value === true) {
            ApiService.delete(`users/${contact.id}`).then((response) => {
              this.getContacts();
            });
          } else {
            console.log("User Rejected");
          }
        })
        .catch((err) => {});
    },
    getUsers() {
      ApiService.query("users/all").then((response) => {
        this.users = response.data.sort((a, b) => (a.name > b.name ? 1 : -1));
      });
    },
    editUser(user) {
      ApiService.get(`users/${user.id}`).then((res) => {
        this.selectedUser = res.data;
        this.newUser = false;

        this.$refs["my-modal"].show();
      });
    },
    addUser() {
      this.selectedUser = new Object({
        username: "",
      });

      this.newUser = true;
      this.$refs["my-modal"].show();
    },
    submitUser() {
      // if (this.newUser) {
      //   ApiService.post(`users`, this.selectedUser).then((res) => {
      //     this.getUsers();
      //   });
      // } else {
      //   ApiService.put(`users`, this.selectedUser).then((res) => {
      //     this.getUsers();
      //   });
      // }

      this.$refs["my-modal"].hide();
    },
    disableSubmit() {
      if (this.selectedUser.userName === "") {
        return true;
      } else {
        return false;
      }
    },
    makeid(length) {
      var result = "";
      var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Vue Bootstrap", route: "alert" }, { title: "" }]);

    this.selectedUser = new Object({
      userName: "",
    });

    this.getUsers();
  },
};
</script>
